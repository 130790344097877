import React from "react";
import { graphql } from "gatsby";
import * as PrivacyHtml from "../Legal/mobile/privacyHtml";
import Layout from "../components/layout/index";
import SEO from "../components/seo";

const Policy = () => {
  // const { i18n } = useTranslation();
  // const currentLang = i18n.language;

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <div
        style={{
          padding: "2em 1em",
          // overflow: "scroll",
          fontSize: "1em",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            maxWidth: 960,
          }}
          dangerouslySetInnerHTML={{
            __html: PrivacyHtml["EN"].html,
            // currentLang === "th"
            //   ? PrivacyHtml["TH"].html
            //   : PrivacyHtml["EN"].html,
          }}
        />
      </div>
    </Layout>
  );
};
export default Policy;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
